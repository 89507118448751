import React, { useState, useEffect, useCallback } from "react";

const Chunk = ({ x, y, specialCells, onHover, handleTooltipClose }) => {
  const renderSpecialCells = () => {
    return specialCells.map((cell, index) => (
      <div
        key={index}
        style={{
          width: "8px",
          height: "8px",
          border: "1px solid grey",
          backgroundColor: "yellow",
          position: "absolute",
          left: `${cell[0] * 8}px`,
          top: `${cell[1] * 8}px`,
          boxSizing: "border-box",
        }}
        onMouseEnter={(e) => onHover(e, cell[0], cell[1], x, y)}
        onMouseMove={(e) => onHover(e, cell[0], cell[1], x, y)}
        onMouseLeave={() => handleTooltipClose()} // close tooltip
      ></div>
    ));
  };

  return (
    <div
      style={{
        width: "800px",
        height: "800px",
        position: "relative",
        border: "1px solid black",
      }}
    >
      {renderSpecialCells()}
      <div style={{ gridColumn: "1 / -1" }}>{`Chunk (${x}, ${y})`}</div>
    </div>
  );
};

const Map = () => {
  const chunkSize = 800; // 100 cells * 8px per cell
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);
  const [offsetX, setOffsetX] = useState(chunkSize/2);
  const [offsetY, setOffsetY] = useState(chunkSize/2);
  const [chunks, setChunks] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const radius = 1; // Adjust this value to fill the viewport

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
      setOffsetX(0); //(window.innerWidth / 2) - chunkSize * 2
      setOffsetY(0); //(window.innerHeight / 2) - chunkSize * 2
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const initialChunks = [];
    for (let i = -radius; i <= radius; i++) {
      for (let j = -radius; j <= radius; j++) {
        initialChunks.push({ x: centerX + i, y: centerY + j });
      }
    }
    setChunks(initialChunks);
  }, [centerX, centerY]);

  useEffect(() => {
    console.log(chunks);
  }, [chunks]);

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (dragging) {
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        setOffsetX((prevOffsetX) => prevOffsetX + deltaX);
        setOffsetY((prevOffsetY) => prevOffsetY + deltaY);

        setStartX(e.clientX);
        setStartY(e.clientY);

        // Check if new chunks need to be added
        const threshold = chunkSize * 0.3; // 30% of chunk size
        if (Math.abs(offsetX) > threshold || Math.abs(offsetY) > threshold) {
          const deltaXChunks = Math.floor(offsetX / chunkSize);
          const deltaYChunks = Math.floor(offsetY / chunkSize);
          setCenterX((prevCenterX) => prevCenterX - deltaXChunks);
          setCenterY((prevCenterY) => prevCenterY + deltaYChunks); // Invert Y-axis
          setOffsetX((prevOffsetX) => prevOffsetX - deltaXChunks * chunkSize);
          setOffsetY((prevOffsetY) => prevOffsetY - deltaYChunks * chunkSize);
          appendChunks();
        }
      }
    },
    [dragging, offsetX, offsetY, startX, startY, chunkSize]
  );

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    setStartX(e.touches[0].clientX);
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = useCallback(
    (e) => {
      if (dragging) {
        const deltaX = e.touches[0].clientX - startX;
        const deltaY = e.touches[0].clientY - startY;

        setOffsetX((prevOffsetX) => prevOffsetX + deltaX);
        setOffsetY((prevOffsetY) => prevOffsetY + deltaY);

        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);

        // Check if new chunks need to be added
        const threshold = chunkSize * 0.3; // 30% of chunk size
        if (Math.abs(offsetX) > threshold || Math.abs(offsetY) > threshold) {
          const deltaXChunks = Math.floor(offsetX / chunkSize);
          const deltaYChunks = Math.floor(offsetY / chunkSize);
          setCenterX((prevCenterX) => prevCenterX - deltaXChunks);
          setCenterY((prevCenterY) => prevCenterY + deltaYChunks); // Invert Y-axis
          setOffsetX((prevOffsetX) => prevOffsetX - deltaXChunks * chunkSize);
          setOffsetY((prevOffsetY) => prevOffsetY - deltaYChunks * chunkSize);
          appendChunks();
        }
      }
    },
    [dragging, offsetX, offsetY, startX, startY]
  );

  const handleTouchEnd = () => {
    setDragging(false);
  };

  // const appendChunks = () => {
  //   setChunks((prevChunks) => {
  //     const newChunks = [];
  //     const existingChunkSet = new Set(
  //       prevChunks.map((chunk) => `${chunk.x},${chunk.y}`)
  //     );
  //     const chunkVisibilityThreshold = 0.3 * chunkSize; // 30% of chunk size

  //     for (let i = -radius; i <= radius; i++) {
  //       for (let j = -radius; j <= radius; j++) {
  //         const chunkX = centerX + i;
  //         const chunkY = centerY + j;
  //         const chunkKey = `${chunkX},${chunkY}`;

  //         // Check if the chunk is already in the set
  //         if (!existingChunkSet.has(chunkKey)) {
  //           const leftEdge =
  //             (chunkX - centerX) * chunkSize + offsetX;
  //           const rightEdge = leftEdge + chunkSize;
  //           const topEdge =
  //             (chunkY - centerY) * chunkSize + offsetY;
  //           const bottomEdge = topEdge + chunkSize;

  //           // Check if the chunk is more than 30% visible
  //           const visibleWidth = Math.min(rightEdge, viewportWidth) - Math.max(leftEdge, 0);
  //           const visibleHeight = Math.min(bottomEdge, viewportHeight) - Math.max(topEdge, 0);
  //           const visibleArea = visibleWidth * visibleHeight;
  //           const chunkArea = chunkSize * chunkSize;

  //           if (visibleArea / chunkArea > 0.3) {
  //             newChunks.push({ x: chunkX, y: chunkY });
  //             existingChunkSet.add(chunkKey);
  //           }
  //         }
  //       }
  //     }

  //     return [...prevChunks, ...newChunks];
  //   });
  // };

  const appendChunks = () => {
    setChunks((prevChunks) => {
      const newChunks = [];
      const existingChunkSet = new Set(
        prevChunks.map((chunk) => `${chunk.x},${chunk.y}`)
      );

      for (let i = -radius; i <= radius; i++) {
        for (let j = -radius; j <= radius; j++) {
          const chunkX = centerX + i;
          const chunkY = centerY + j;
          const chunkKey = `${chunkX},${chunkY}`;

          if (!existingChunkSet.has(chunkKey)) {
            newChunks.push({ x: chunkX, y: chunkY });
            existingChunkSet.add(chunkKey);
          }
        }
      }

      return [...prevChunks, ...newChunks];
    });
  };

  useEffect(() => {
    // Fetch data from blockchain based on visible chunks
    const fetchDataForVisibleChunks = () => {
      const visibleChunks = chunks.filter((chunk) => {
        const leftEdge =
          (chunk.x - centerX) * chunkSize + offsetX;
        const rightEdge = leftEdge + chunkSize;
        const topEdge =
          (chunk.y - centerY) * chunkSize + offsetY;
        const bottomEdge = topEdge + chunkSize;
        return (
          rightEdge > 0 &&
          leftEdge < viewportWidth &&
          bottomEdge > 0 &&
          topEdge < viewportHeight
        );
      });
      visibleChunks.forEach((chunk) => {
        // Fetch data from blockchain using chunk.x and chunk.y
        // console.log(`Fetching data for chunk (${chunk.x}, ${chunk.y})`);
        // Call your blockchain fetch function here
      });
    };

    fetchDataForVisibleChunks();
  }, [chunks, centerX, centerY, offsetX, offsetY, viewportWidth, viewportHeight]);

  // Unload chunks that are not visible
  useEffect(() => {
    const unloadInvisibleChunks = () => {
      setChunks((prevChunks) =>
        prevChunks.filter((chunk) => {
          const leftEdge = (chunk.x - centerX) * chunkSize + offsetX;
          const rightEdge = leftEdge + chunkSize;
          const topEdge = (chunk.y - centerY) * chunkSize + offsetY;
          const bottomEdge = topEdge + chunkSize;
          return (
            rightEdge > -chunkSize &&
            leftEdge < viewportWidth + chunkSize &&
            bottomEdge > -chunkSize &&
            topEdge < viewportHeight + chunkSize
          );
        })
      );
    };

    unloadInvisibleChunks();
  }, [centerX, centerY, offsetX, offsetY, viewportWidth, viewportHeight]);

  // Add event listeners for mousemove and mouseup on the entire document
  useEffect(() => {
    const handleMouseUpDocument = () => {
      setDragging(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUpDocument);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUpDocument);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleMouseMove, handleTouchMove]);

  const handleCenterClick = () => {
    setDragging(false);
    setStartX(0);
    setStartY(0);
    setOffsetX(chunkSize / 2); //viewportWidth / 2 - chunkSize / 2
    setOffsetY(chunkSize / 2); // viewportHeight / 2 - chunkSize / 2
    setCenterX(0);
    setCenterY(0);
  };

  const specialCells = [
    [10, 12],
    [15, 35],
    [55, 55],
    [10, 70],
    [99, 99],
    [97, 97],
  ];
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCellHover = (e, cellX, cellY, chunkX, chunkY) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    // Calculate the position of the tooltip relative to the viewport
    const tooltipX = rect.left + offsetX + 10; // Add 10px offset for better visibility
    const tooltipY = rect.top + offsetY + 10;
    console.log(cellX, cellY);
    setTooltipPosition({ x: tooltipX, y: tooltipY, cellX, cellY });
    setShowTooltip(true);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "20%",
          height: "100px",
          border: "1px solid red",
          bottom: "10px",
          left: "40%",
          zIndex: "10",
        }}
      >
        <button onClick={handleCenterClick}>Center</button>
      </div>
      <div
        style={{
          width: viewportWidth,
          height: viewportHeight,
          border: "1px solid black",
          position: "relative",
          overflow: "hidden",
          userSelect: "none", // Prevent text selection while dragging
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <div
          id="mapDiv"
          style={{
            position: "absolute",
            left: offsetX,
            top: offsetY,
          }}
        >
          {chunks.map((chunk, index) => (
            <div
              className="chunk"
              key={index}
              style={{
                position: "absolute",
                left: (chunk.x - centerX) * chunkSize + viewportWidth / 2 - chunkSize,
                top: (-chunk.y + centerY) * chunkSize + viewportHeight / 2 - chunkSize, // Invert Y-axis
              }}
            >
              <Chunk
                x={chunk.x}
                y={chunk.y}
                specialCells={specialCells}
                onHover={handleCellHover}
                handleTooltipClose={handleTooltipClose}
              />
            </div>
          ))}
        </div>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              left: tooltipPosition.x,
              top: tooltipPosition.y,
              backgroundColor: "white",
              padding: "5px",
              border: "1px solid black",
            }}
          >
            {`(${tooltipPosition.cellX}, ${tooltipPosition.cellY})`}
            <button onClick={handleTooltipClose}>Close</button>
          </div>
        )}
      </div>
    </>
  );
};

export default Map;
